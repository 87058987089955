import React, { useState } from 'react';

const TripNoteForm = ({ tripData, refreshTables, closeTripNoteModal }) => {
  const [formData, setFormData] = useState({
    completion_note: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(tripData);
    
    const token = localStorage.getItem('access_token');
    const url = `${window.location.protocol}//${window.location.hostname}:8443/api/trips/${tripData.id}/`
    const method = 'PUT';

    const updatedTripData = {
        ...tripData,
        completion_note: formData.completion_note
    }

    fetch(url, { // Adjust this URL to your jobs endpoint
        method: method,
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }),
        body: JSON.stringify(updatedTripData),
    })
    .then(response => {
        if (!response.ok) {
            // Convert non-2xx HTTP responses into errors so the catch block can handle them
            return response.json().then(data => Promise.reject(data));
        }
        return response.json();
    })
    .then(data => {
        setFormData({
            completion_note: ''
        });
        closeTripNoteModal();
        refreshTables();
    })
    .catch(error => {
        let errorMessage = '';

        Object.keys(error).forEach(key => {
            // Check if the property is an array and has at least one message
            if (Array.isArray(error[key]) && error[key].length > 0) {
                // Append the first error message of each field to the errorMessage string
                errorMessage += `${key}: ${error[key][0]}\n`;
            }
        });

        //if (error.code == 'token_not_valid') {
        //    logout();
        //}

        //notify(`Error adding job: ${errorMessage}`);
        console.error('Error adding job:', error);
    });

  };

  return (
    <div className="container">
        <form onSubmit={handleSubmit} className="form">
        <div className="formGroup">
            <label htmlFor="completion_note" className="label">Completion Note:</label>
            <input
                type="text"
                id="completion_note"
                name="completion_note"
                value={formData.completion_note}
                onChange={handleChange}
                className="input"
            />
        </div>
        <button type="submit" className="button">Save</button>
        </form>
    </div>
  );
};

export default TripNoteForm;
