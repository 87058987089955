import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';

const MetricForm = ({ job, onSuccess, onError, logout }) => {
    const [metricTypes, setMetricTypes] = useState([]);
    const [vicinities, setVicinities] = useState([]);
    const [specificLocations, setSpecificLocations] = useState([]);
    const [metricFormData, setMetricFormData] = useState({
        job: job.id,
        metric_type: '',
        vicinity: '',
        specific_location: '',
        other_metric_type: '',
        other_specific_location: ''
    });

    const notify = (text) => toast(text);

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        });

        fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/metrictypes/?format=json`, {
            method: 'GET',
            headers: headers,
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to fetch metric types');
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            setMetricTypes(data); // Assuming your API returns an array of clients
        })
        .catch(error => {
            if (error.code === 'token_not_valid') {
                logout();
            }

            let errorMessage = '';

            Object.keys(error).forEach(key => {
                if (Array.isArray(error[key]) && error[key].length > 0) {
                    errorMessage += `${key}: ${error[key][0]}\n`;
                }
            });

            notify(`Error fetching metric types: ${errorMessage}`);
            console.error('Error fetching metric types:', error);
        });

        fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/vicinities/?format=json`, {
            method: 'GET',
            headers: headers,
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to fetch vicinities');
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            setVicinities(data); // Assuming your API returns an array of clients
        })
        .catch(error => {
            if (error.code === 'token_not_valid') {
                logout();
            }

            let errorMessage = '';

            Object.keys(error).forEach(key => {
                if (Array.isArray(error[key]) && error[key].length > 0) {
                    errorMessage += `${key}: ${error[key][0]}\n`;
                }
            });

            notify(`Error fetching vicinities: ${errorMessage}`);
            console.error('Error fetching vicinities:', error);
        });

        fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/specificlocations/?format=json`, {
            method: 'GET',
            headers: headers,
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to fetch specific locations');
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            setSpecificLocations(data);
        })
        .catch(error => {
            if (error.code === 'token_not_valid') {
                logout();
            }

            let errorMessage = '';

            Object.keys(error).forEach(key => {
                if (Array.isArray(error[key]) && error[key].length > 0) {
                    errorMessage += `${key}: ${error[key][0]}\n`;
                }
            });

            notify(`Error fetching specific locations: ${errorMessage}`);
            console.error('Error fetching specific locations:', error);
        });
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setMetricFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const addMetric = () => {
        const token = localStorage.getItem('access_token');
        const headers = new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        });

        fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/metrics/`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(metricFormData),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(() => {
            setMetricFormData({
                ...metricFormData,
                metric_type: '',
                vicinity: '',
                specific_location: '',
                other_metric_type: '',
                other_specific_location: ''
            });
            if (onSuccess) onSuccess();
        })
        .catch(error => {
            let errorMessage = '';
            Object.keys(error).forEach(key => {
                if (Array.isArray(error[key]) && error[key].length > 0) {
                    errorMessage += `${key}: ${error[key][0]}\n`;
                }
            });

            if (error.code === 'token_not_valid') {
                logout();
            }

            notify(`Error adding metric: ${errorMessage}`);
            if (onError) onError(error);
            console.error('Error adding metric:', error);
        });
    };

    const isOtherMetricTypeVisible = metricFormData.metric_type === '7';
    const isOtherSpecificLocationVisible = metricFormData.specific_location === '15';

    return (
        <>
            <div className="metricForm">
                <div className="formGroup">
                    <label htmlFor="metric_type">Metric Type:</label>
                    <select name="metric_type" value={metricFormData.metric_type} onChange={handleChange} className="input">
                        <option value="">Select Metric Type</option>
                        {metricTypes.map(metricType => (
                            <option key={metricType.id} value={metricType.id}>{metricType.name}</option>
                        ))}
                    </select>
                </div>

                {isOtherMetricTypeVisible && (
                    <div className="formGroup">
                        <label htmlFor="other_metric_type">Other Metric Type:</label>
                        <input
                            type="text"
                            name="other_metric_type"
                            value={metricFormData.other_metric_type}
                            onChange={handleChange}
                            className="input"
                        />
                    </div>
                )}

                <div className="formGroup">
                    <label htmlFor="vicinity">Vicinity:</label>
                    <select name="vicinity" value={metricFormData.vicinity} onChange={handleChange} className="input">
                        <option value="">Select Vicinity</option>
                        {vicinities.map(vicinity => (
                            <option key={vicinity.id} value={vicinity.id}>{vicinity.name}</option>
                        ))}
                    </select>
                </div>

                <div className="formGroup">
                    <label htmlFor="specific_location">Specific Location:</label>
                    <select name="specific_location" value={metricFormData.specific_location} onChange={handleChange} className="input">
                        <option value="">Select Specific Location</option>
                        {specificLocations.map(location => (
                            <option key={location.id} value={location.id}>{location.name}</option>
                        ))}
                    </select>
                </div>

                {isOtherSpecificLocationVisible && (
                    <div className="formGroup hidden">
                        <label htmlFor="other_specific_location">Other Specific Location:</label>
                        <input
                            type="text"
                            name="other_specific_location"
                            value={metricFormData.other_specific_location}
                            onChange={handleChange}
                            className="input"
                        />
                    </div>
                )}

                <div className="formGroup">
                    <button type="submit" onClick={addMetric} className="button">Add Metric</button>
                </div>
            </div>
        </>
    );

};

export default MetricForm;