import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { getUserIdFromToken } from '../utils';
import 'react-toastify/dist/ReactToastify.css';
import chevronRight from './chevronRight.png';
import chevronDown from './chevronDown.png';
import './index.css';

const NewJob = ({ refreshTables, closeModal }) => {
    const [formData, setFormData] = useState({
        date_assigned: '',
        status: 'BID',
        user: getUserIdFromToken(),
        client: '',
        company: '',
        customer_reported: '',
        job_site_address: '',
        bill_to_name: '',
        bill_to_address: '',
        bill_to_phone_number: '',
        bill_to_email_address: '',
        land_owner_name: '',
        land_owner_address: '',
        land_owner_phone_number: '',
        land_owner_email_address: '',
        resident_name: '',
        resident_phone_number: '',
        resident_email_address: '',
        scope_of_work: '',
        year_built: '',
        estimated_days: '0',
        contractor: '',
        permit_required: '',
        permit_description: '',
        permit_total: '0',
        emergency: false,
        latitude: 0,
        longitude: 0,
        permit_required: false,
        water: '',
        sewer: '',
        gate_code: '',
        lockbox_code: '',
        number_of_bedrooms: '0',
        number_of_bathrooms: '0',
        square_footage: '0',
        internal_notes: '',
    });

    const [clients, setClients] = useState([]);

    const [repeatCustomers, setRepeatCustomers] = useState([]);

    const [clientName, setClientName] = useState('');

    const [showAddClient, setShowAddClient] = useState(false);

    const [visibleBillTo, setVisibleBillTo] = useState(false);

    const [visibleLandOwner, setVisibleLandOwner] = useState(false);

    const [visibleResident, setVisibleResident] = useState(false);

    const notify = (text) => toast(text);

    const logout = () => {
        // Remove the tokens from localStorage or any other storage you are using
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
    
        // Redirect the user to the login page or home page as required
        window.location.href = '/login';
    };

    useEffect(() => {
        // Fetch clients from your API and update state
        fetchClients();
    }, []);

    const toggleBillToVisibility = () => {
        setVisibleBillTo(!visibleBillTo);
    };

    const toggleLandOwnerVisibility = () => {
        setVisibleLandOwner(!visibleLandOwner);
    };

    const toggleResidentVisibility = () => {
        setVisibleResident(!visibleResident);
    };

    const handleChange = (e) => {
        const { name, type, checked, value } = e.target;
        const actualValue = type === 'checkbox' ? checked : value;

        setFormData({
            ...formData,
            [name]: actualValue,
        });
       
        if (name === 'client') {
            setClientName(e.target.options[e.target.selectedIndex].text);

            if (e.target.options[e.target.selectedIndex].text === 'Repeat Customer') {
                const token = localStorage.getItem('access_token');
                const headers = new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                });

                fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/repeat-customers/?format=json`, {
                    method: 'GET',
                    headers: headers,
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch repeat customers');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log(data);
                    setRepeatCustomers(data);
                })
                .catch(error => {
                    if (error.code == 'token_not_valid') {
                        logout();
                    }

                    let errorMessage = '';

                    Object.keys(error).forEach(key => {
                        // Check if the property is an array and has at least one message
                        if (Array.isArray(error[key]) && error[key].length > 0) {
                            // Append the first error message of each field to the errorMessage string
                            errorMessage += `${key}: ${error[key][0]}\n`;
                        }
                    });

                    notify(`Error fetching repeat customers: ${errorMessage}`);
                    console.error('Error fetching repeat customers:', error);
                });
            }
        }
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        saveJob();
    };

    const fetchClients = () => {
        const token = localStorage.getItem('access_token');
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        });

        fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/clients/?format=json`, {
            method: 'GET',
            headers: headers,
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to fetch clients');
            }
            return response.json();
        })
        .then(data => {
            setClients(data); // Assuming your API returns an array of clients
        })
        .catch(error => {
            if (error.code == 'token_not_valid') {
                logout();
            }

            let errorMessage = '';

            Object.keys(error).forEach(key => {
                // Check if the property is an array and has at least one message
                if (Array.isArray(error[key]) && error[key].length > 0) {
                    // Append the first error message of each field to the errorMessage string
                    errorMessage += `${key}: ${error[key][0]}\n`;
                }
            });

            notify(`Error fetching clients: ${errorMessage}`);
            console.error('Error fetching clients:', error);
        });
    };    
    
    const saveJob = () => {
        const token = localStorage.getItem('access_token');
        const url = `${window.location.protocol}//${window.location.hostname}:8443/api/jobs/`; // Adjust the URL for adding a new job
        const method = 'POST'; 

        fetch(url, { // Adjust this URL to your jobs endpoint
            method: method,
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }),
            body: JSON.stringify(formData),
        })
        .then(response => {
            if (!response.ok) {
                // Convert non-2xx HTTP responses into errors so the catch block can handle them
                return response.json().then(data => Promise.reject(data));
            }
            return response.json();
        })
        .then(data => {
            notify(`Successfully Saved!`);
            refreshTables();
            setShowAddClient(!showAddClient);
            closeModal();
        })
        .catch(error => {
            let errorMessage = '';

            Object.keys(error).forEach(key => {
                // Check if the property is an array and has at least one message
                if (Array.isArray(error[key]) && error[key].length > 0) {
                    // Append the first error message of each field to the errorMessage string
                    errorMessage += `${key}: ${error[key][0]}\n`;
                }
            });

            if (error.code == 'token_not_valid') {
                logout();
            }

            notify(`Error adding job: ${errorMessage}`);
            console.error('Error adding job:', error);
        });
    };

    const copyJobAddress = () => {
        setFormData({
            ...formData,
            bill_to_address: formData.job_site_address
        });
    };

  return (
    <div className="container">
      <form onSubmit={handleSubmit} className="form"> 

      <div className="formGroup">
            <label htmlFor="client" className="label"><span>* Customer Type</span></label>
            <select
                id="client"
                name="client"
                value={formData.client}
                onChange={handleChange}
                className="select"
                required
            >
                <option value="">Select Customer</option>
                {clients.map(client => (
                    <option key={client.id} value={client.id}>{client.name}</option>
                ))}
            </select>
        </div>

        {clientName === 'Repeat Customer' ? (
            <>
                <div className="formGroup">
                    <label htmlFor="repeatCustomer" className="label"><span>Select Repeat Customer to Autopopulate Data</span></label>
                    <select
                        id="repeatCustomer"
                        className="select"
                        onChange={(e) => {
                            let bill_to_name = e.target.value.split('|')[0];
                            let job_site_address = e.target.value.split('|')[1];
                            const selectedCustomer = repeatCustomers.find(
                                (customer) => customer.bill_to_name === bill_to_name && customer.job_site_address === job_site_address
                            );
                            if (selectedCustomer) {
                                setFormData({
                                    ...formData,
                                    job_site_address: selectedCustomer.job_site_address,
                                    bill_to_name: selectedCustomer.bill_to_name,
                                    bill_to_address: selectedCustomer.bill_to_address,
                                    bill_to_phone_number: selectedCustomer.bill_to_phone_number,
                                    bill_to_email_address: selectedCustomer.bill_to_email_address,
                                    land_owner_name: selectedCustomer.land_owner_name,
                                    land_owner_address: selectedCustomer.land_owner_address,
                                    land_owner_phone_number: selectedCustomer.land_owner_phone_number,
                                    land_owner_email_address: selectedCustomer.land_owner_email_address,
                                });
                            } else {
                                setFormData({
                                    ...formData,
                                    job_site_address: '',
                                    bill_to_name: '',
                                    bill_to_address: '',
                                    bill_to_phone_number: '',
                                    bill_to_email_address: '',
                                    land_owner_name: '',
                                    land_owner_address: '',
                                    land_owner_phone_number: '',
                                    land_owner_email_address: '',
                                });
                            }
                        }}
                    >
                        <option value="">Select Repeat Customer</option>
                        {repeatCustomers.map((customer, index) => (
                            <option key={index} value={customer.bill_to_name + '|' + customer.job_site_address}>
                                {customer.bill_to_name} - {customer.job_site_address}
                            </option>
                        ))}
                    </select>
                </div>
            </>
        ) : ''}

        <div className="formGroup">
            <label htmlFor="customer_reported" className="label"><span>* Customer Reported:</span></label>
            <input
                id="customer_reported"
                name="customer_reported"
                value={formData.customer_reported}
                onChange={handleChange}
                className="input"
                required
            />
        </div>

        <div className="formGroup">
            <label htmlFor="internal_notes" className="label"><span>Internal Notes:</span></label>
            <input
                id="internal_notes"
                name="internal_notes"
                value={formData.internal_notes}
                onChange={handleChange}
                className="input"
            />
        </div>

        <div className="formGroup">
            <label htmlFor="job_site_address" className="label"><span>* Job Address:</span></label>
            <input
                id="job_site_address"
                name="job_site_address"
                value={formData.job_site_address}
                onChange={handleChange}
                className="input"
                required
            />
        </div>

        <div className="formGroup"> 
          <label htmlFor="date_assigned" className="label"><span>* Date Assigned:</span></label> 
          <input
            type="date"
            id="date_assigned"
            name="date_assigned"
            value={formData.date_assigned}
            onChange={handleChange}
            className="input"
            required
          />
        </div>

        <div className="separator clickable" onClick={toggleBillToVisibility}>
            <img src={visibleBillTo ? chevronDown : chevronRight} /> Bill To
        </div>

        { visibleBillTo ? (
        <>
            <div className="formGroup">
                <label htmlFor="bill_to_name" className="label"><span>* Bill To Name:</span></label>
                <input
                    id="bill_to_name"
                    name="bill_to_name"
                    value={formData.bill_to_name}
                    onChange={handleChange}
                    className="input"
                    required
                />
            </div>

            <div className="formGroup">
                <label htmlFor="bill_to_address" className="label">
                    <span className="spanLabel">* Bill To Address:</span>
                    <span className="clickable greenie" onClick={copyJobAddress}>Same as Job Address</span>
                </label>
                <input
                    id="bill_to_address"
                    name="bill_to_address"
                    value={formData.bill_to_address}
                    onChange={handleChange}
                    className="input"
                    required
                />
            </div>

            <div className="formGroup">
                <label htmlFor="bill_to_phone_number" className="label"><span>* Bill To Phone Number:</span></label>
                <input
                    id="bill_to_phone_number"
                    name="bill_to_phone_number"
                    value={formData.bill_to_phone_number}
                    onChange={handleChange}
                    className="input"
                    required
                />
            </div>

            <div className="formGroup">
                <label htmlFor="bill_to_email_address" className="label"><span>* Bill To Email Address:</span></label>
                <input
                    id="bill_to_email_address"
                    name="bill_to_email_address"
                    value={formData.bill_to_email_address}
                    onChange={handleChange}
                    className="input"
                    required
                />
            </div>
        </>
        ) : ''}

        <div className="separator clickable" onClick={toggleLandOwnerVisibility}>
            <img src={visibleLandOwner ? chevronDown : chevronRight} /> Land Owner
        </div>

        { visibleLandOwner ? (
        <>

            <div className="formGroup">
                <label htmlFor="land_owner_name" className="label"><span>Land Owner Name:</span></label>
                <input
                    id="land_owner_name"
                    name="land_owner_name"
                    value={formData.land_owner_name}
                    onChange={handleChange}
                    className="input"
                />
            </div>

            <div className="formGroup">
                <label htmlFor="land_owner_address" className="label"><span>Land Owner Address:</span></label>
                <input
                    id="land_owner_address"
                    name="land_owner_address"
                    value={formData.land_owner_address}
                    onChange={handleChange}
                    className="input"
                />
            </div>

            <div className="formGroup">
                <label htmlFor="land_owner_phone_number" className="label"><span>Land Owner Phone Number:</span></label>
                <input
                    id="land_owner_phone_number"
                    name="land_owner_phone_number"
                    value={formData.land_owner_phone_number}
                    onChange={handleChange}
                    className="input"
                />
            </div>

            <div className="formGroup">
                <label htmlFor="land_owner_email_address" className="label"><span>Land Owner Email Address:</span></label>
                <input
                    id="land_owner_email_address"
                    name="land_owner_email_address"
                    value={formData.land_owner_email_address}
                    onChange={handleChange}
                    className="input"
                />
            </div>
        </>
        ) : ''}

        <div className="separator clickable" onClick={toggleResidentVisibility}>
            <img src={visibleResident ? chevronDown : chevronRight} /> Resident
        </div>

        { visibleResident ? (
        <>

            <div className="formGroup">
                <label htmlFor="resident_name" className="label"><span>Resident Name:</span></label>
                <input
                    id="resident_name"
                    name="resident_name"
                    value={formData.resident_name}
                    onChange={handleChange}
                    className="input"
                />
            </div>

            <div className="formGroup">
                <label htmlFor="resident_phone_number" className="label"><span>Resident Phone Number:</span></label>
                <input
                    id="resident_phone_number"
                    name="resident_phone_number"
                    value={formData.resident_phone_number}
                    onChange={handleChange}
                    className="input"
                />
            </div>

            <div className="formGroup">
                <label htmlFor="resident_email_address" className="label"><span>Resident Email Address:</span></label>
                <input
                    id="resident_email_address"
                    name="resident_email_address"
                    value={formData.resident_email_address}
                    onChange={handleChange}
                    className="input"
                />
            </div>
        </>
        ) : ''}

        <div className="separator">
            Property
        </div>

        <div className="fieldset-grid">

            <div className="formGroup">
                <label htmlFor="gate_code" className="label"><span>Gate Code:</span></label>
                <input
                    type="text"
                    id="gate_code"
                    name="gate_code"
                    value={formData.gate_code}
                    onChange={handleChange}
                    className="input"
                />
            </div>

            <div className="formGroup">
                <label htmlFor="lockbox_code" className="label"><span>Lockbox Code:</span></label>
                <input
                    type="text"
                    id="lockbox_code"
                    name="lockbox_code"
                    value={formData.lockbox_code}
                    onChange={handleChange}
                    className="input"
                />
            </div>

            <div className="formGroup">
                <label className="label"><span>Water:</span></label>
                <div>
                    <label htmlFor="meter" className="radioLabel">
                        <input
                            type="radio"
                            id="meter"
                            name="water"
                            value="meter"
                            checked={formData.water === "meter"}
                            onChange={handleChange}
                            className="radioInput"
                        />
                        Meter
                    </label>
                    <span>&nbsp;</span>
                    <label htmlFor="well" className="radioLabel">
                        <input
                            type="radio"
                            id="well"
                            name="water"
                            value="well"
                            checked={formData.water === "well"}
                            onChange={handleChange}
                            className="radioInput"
                        />
                        Well
                    </label>
                </div>
            </div>

            <div className="formGroup">
                <label className="label"><span>Sewer:</span></label>
                <div>
                    <label htmlFor="sewer" className="radioLabel">
                        <input
                            type="radio"
                            id="sewer"
                            name="sewer"
                            value="sewer"
                            checked={formData.sewer === "sewer"}
                            onChange={handleChange}
                            className="radioInput"
                        />
                        Sewer
                    </label>
                    <span>&nbsp;</span>
                    <label htmlFor="septic" className="radioLabel">
                        <input
                            type="radio"
                            id="septic"
                            name="sewer"
                            value="septic"
                            checked={formData.sewer === "septic"}
                            onChange={handleChange}
                            className="radioInput"
                        />
                        Septic
                    </label>
                </div>
            </div>

            <div className="formGroup">
                <label htmlFor="number_of_bedrooms" className="label"><span># of Bedrooms:</span></label>
                <input
                    type="text"
                    id="number_of_bedrooms"
                    name="number_of_bedrooms"
                    value={formData.number_of_bedrooms}
                    onChange={handleChange}
                    className="input"
                />
            </div>

            <div className="formGroup">
                <label htmlFor="number_of_bathrooms" className="label"><span># of Bathrooms:</span></label>
                <input
                    type="text"
                    id="number_of_bathrooms"
                    name="number_of_bathrooms"
                    value={formData.number_of_bathrooms}
                    onChange={handleChange}
                    className="input"
                />
            </div>

            <div className="formGroup">
                <label htmlFor="square_footage" className="label"><span>Square Footage:</span></label>
                <input
                    type="text"
                    id="square_footage"
                    name="square_footage"
                    value={formData.square_footage}
                    onChange={handleChange}
                    className="input"
                />
            </div>
            
            <div className="formGroup">
                <label htmlFor="year_built" className="label"><span>Year Built:</span></label>
                <input
                    id="year_built"
                    name="year_built"
                    value={formData.year_built}
                    onChange={handleChange}
                    className="input"
                />
            </div>
        </div>

        <div className="form-group">
            <button type="submit" className="button">Save</button> 
        </div>

      </form>
      <ToastContainer />
    </div>
  );
};

export default NewJob;
