import React, { useState, useEffect } from 'react';
import AddressForm from '../../components/addressForm';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddressPage = () => {
    const notify = (text) => toast(text);

    const logout = () => {
        // Remove the tokens from localStorage or any other storage you are using
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
    
        // Redirect the user to the login page or home page as required
        window.location.href = '/login';
    };

    return (
        <>
            

            <AddressForm
                onSuccess={(data) => {
                    console.log('Successfully added address.');
                }}
                onError={(error) => console.error('Failed to add address:', error)}
                notify={notify}
                logout={logout}
            />
            <ToastContainer />
        </>
    );
};

export default AddressPage;