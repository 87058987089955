import React from 'react';
import './index.css';

const SummaryTable = (props) => {
  const jobsInProgress = props.jobsInProgress 
  const bidJobs = props.bidJobs
  const completedJobsNotInvoiced = props.completedJobsNotInvoiced
  const invoicedJobs = props.invoicedJobs
  const invoicedPile = props.invoicedPile
  const paidJobs = props.paidJobs
  const cancelledJobs = props.cancelledJobs

  return (
    <div className="summarytable">
      <div className="summarytableheader brdiv">amounts due summary</div>
      <div className="summaryline brdiv">
        <div className="summarylabel">In Progress:</div>
        <div className="summaryvalue summaryinprogress">{jobsInProgress}</div>
      </div>
      <div className="summaryline brdiv">
        <div className="summarylabel">Completed:</div>
        <div className="summaryvalue summarycompleted">{invoicedJobs}</div>
      </div>
      <div className="summaryline brdiv">
        <div className="summarylabel summaryinvoicedlabel">Invoiced:</div>
        <div className="summaryvalue summaryinvoiced">{paidJobs}</div>
      </div>
      <div className="summaryline brdiv">
        <div className="summarylabel summaryinvoicedpilelabel">Late Invoices:</div>
        <div className="summaryvalue summaryinvoicedpile">{completedJobsNotInvoiced}</div>
      </div>
      <div className="summaryline brdiv">
        <div className="summarylabel">Invoiced Total:</div>
        <div className="summaryvalue summaryinvoicedtotal">{invoicedPile}</div>
      </div>
    </div>
  );
};

export default SummaryTable;
