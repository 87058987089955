import React, { useState } from 'react';

const JobBlueprintsForm = ({ jobData, refreshTables }) => {
  const [formData, setFormData] = useState({
    job: jobData.id,
    name: '',
    description: '',
    blueprint: null,
  });

  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, blueprint: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);
    const formDataToSend = new FormData();
    formDataToSend.append('job', formData.job);
    formDataToSend.append('name', formData.name);
    formDataToSend.append('description', formData.description);
    formDataToSend.append('blueprint', formData.blueprint);

    const token = localStorage.getItem('access_token');

    try {
      const response = await fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/jobblueprints/`, {
        method: 'POST',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
        }),
        body: formDataToSend,
      });
      if (response.ok) {
        setUploadSuccess(true);
        refreshTables();
      } else {
        console.error('Error uploading site plans:', response.statusText);
        setUploadSuccess(false);
      }
    } catch (error) {
      console.error('Error uploading plans:', error);
      setUploadSuccess(false);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="container">
        <form onSubmit={handleSubmit} className="form">
        <div className="formGroup">
            <input
            type="hidden"
            id="job"
            name="job"
            value={formData.job}
            onChange={handleChange}
            className="input"
            required
            />
        </div>
        <div className="formGroup">
            <label htmlFor="name" className="label">Name:</label>
            <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="input"
            />
        </div>
        <div className="formGroup">
            <label htmlFor="description" className="label">Description:</label>
            <input
            type="text"
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="input"
            />
        </div>
        <div className="formGroup">
            <label htmlFor="blueprint" className="label">Site Plan:</label>
            <input
            type="file"
            id="blueprint"
            name="blueprint"
            onChange={handleFileChange}
            required
            className="input"
            />
        </div>
        <button type="submit" disabled={uploading} className="button">
            {uploading ? 'Uploading...' : 'Submit'}
        </button>
        {uploadSuccess && <p>Site Plans uploaded successfully!</p>}
        </form>
    </div>
  );
};

export default JobBlueprintsForm;
