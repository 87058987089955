import React, { createContext, useReducer, useContext } from 'react';

const SelectionContext = createContext();

const selectionReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_ID':
      return [...state, action.payload];
    case 'REMOVE_ID':
      return state.filter((job) => job.id !== action.payload.id);
    default:
      return state;
  }
};

export const useSelection = () => {
  return useContext(SelectionContext);
};

export const SelectionProvider = ({ children }) => {
  const [selectedJobs, dispatch] = useReducer(selectionReducer, []);

  const handleCheckboxChange = (id, isChecked) => {
        if (isChecked) {
        dispatch({ type: 'ADD_ID', payload: id });
        } else {
        dispatch({ type: 'REMOVE_ID', payload: id });
        }
    }

    const getSelectedIds = () => {
        return selectedJobs.map((job) => job.id);
    };

  // Getter method to return an array of selected job IDs
  return (
    <SelectionContext.Provider value={{ selectedJobs, handleCheckboxChange, getSelectedIds }}>
      {children}
    </SelectionContext.Provider>
  );
};
