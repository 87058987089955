import React, { createContext, useState, useContext, useEffect } from 'react';

const JobsContext = createContext();

export const JobsProvider = ({ children }) => {
  const [jobsData, setJobsData] = useState({
    bids: [],
    jobsInProgress: [],
    completedJobsNotInvoiced: [],
    invoicedJobs: [],
    invoicedPile: [],
    paidJobs: [],
    cancelledJobs: []
  });

  const fetchBidJobs = () => {
    const token = localStorage.getItem('access_token');
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    });

    fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/jobs/?format=json&status=BID`, {
        method: 'GET',
        headers: headers,
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    })
    .then(data => {
        setJobsData(prevJobsData => ({
            ...prevJobsData,
            bids: data
        }));
    })
    .catch(error => console.error('Error fetching data:', error));
};

const fetchJobsInProgress = () => {
    const token = localStorage.getItem('access_token');
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    });

    fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/jobs/?format=json&status=IPG`, {
        method: 'GET',
        headers: headers,
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    })
    .then(data => {
        setJobsData(prevJobsData => ({
            ...prevJobsData,
            jobsInProgress: data
        }));
    })
    .catch(error => console.error('Error fetching data:', error));
};

const fetchCompletedJobsNotInvoiced = () => {
    const token = localStorage.getItem('access_token');
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    });

    fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/jobs/?format=json&status=CMP`, {
        method: 'GET',
        headers: headers,
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    })
    .then(data => {
        setJobsData(prevJobsData => ({
            ...prevJobsData,
            completedJobsNotInvoiced: data
        }));
    })
    .catch(error => console.error('Error fetching data:', error));
};

const fetchInvoicedJobs = () => {
    const token = localStorage.getItem('access_token');
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    });

    fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/jobs/?format=json&status=INV`, {
        method: 'GET',
        headers: headers,
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    })
    .then(data => {
        setJobsData(prevJobsData => ({
            ...prevJobsData,
            invoicedJobs: data
        }));
    })
    .catch(error => console.error('Error fetching data:', error));
};

const fetchInvoicedPile = () => {
    const token = localStorage.getItem('access_token');
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    });

    fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/jobs/?format=json&status=IPR`, {
        method: 'GET',
        headers: headers,
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    })
    .then(data => {
        setJobsData(prevJobsData => ({
            ...prevJobsData,
            invoicedPile: data
        }));
    })
    .catch(error => console.error('Error fetching data:', error));
};

const fetchJobsPaid = () => {
    const token = localStorage.getItem('access_token');
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    });

    fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/jobs/?format=json&status=IPD`, {
        method: 'GET',
        headers: headers,
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    })
    .then(data => {
        setJobsData(prevJobsData => ({
            ...prevJobsData,
            paidJobs: data
        }));
    })
    .catch(error => console.error('Error fetching data:', error));
};

const fetchCancelledJobs = () => {
    const token = localStorage.getItem('access_token');
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    });

    fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/jobs/?format=json&status=CNL`, {
        method: 'GET',
        headers: headers,
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    })
    .then(data => {
        setJobsData(prevJobsData => ({
            ...prevJobsData,
            cancelledJobs: data
        }));
    })
    .catch(error => console.error('Error fetching data:', error));
};

  const refreshTables = () => {
    // Fetch functions to update jobs data
    fetchBidJobs();
    fetchJobsInProgress();
    fetchCompletedJobsNotInvoiced();
    fetchInvoicedJobs();
    fetchInvoicedPile();
    fetchJobsPaid();
    fetchCancelledJobs();
  };

  useEffect(() => {
    refreshTables();
  }, []);

  return (
    <JobsContext.Provider value={{ jobsData, refreshTables }}>
      {children}
    </JobsContext.Provider>
  );
};

export const useJobs = () => {
  return useContext(JobsContext);
};
