import React, { useState, useEffect } from 'react';
import './index.css';

const AddressForm = ({ onSuccess, onError, logout, notify }) => {
    const [clients, setClients] = useState([]);
    const [addressData, setAddressData] = useState({
        client: '',
        address1: '',
        latitude: '',
        longitude: '',
        year_built: '',
        water: '',
        sewer: '',
        gate_code: '',
        lockbox_code: ''
    });

    useEffect(() => {
        fetchClients();
    }, []);

    const fetchClients = () => {
        const token = localStorage.getItem('access_token');
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        });

        fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/clients/?format=json`, {
            method: 'GET',
            headers: headers,
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to fetch clients');
            }
            return response.json();
        })
        .then(data => {
            setClients(data); // Assuming your API returns an array of clients
        })
        .catch(error => {
            if (error.code === 'token_not_valid') {
                logout();
            }

            let errorMessage = '';

            Object.keys(error).forEach(key => {
                if (Array.isArray(error[key]) && error[key].length > 0) {
                    errorMessage += `${key}: ${error[key][0]}\n`;
                }
            });

            notify(`Error fetching clients: ${errorMessage}`);
            console.error('Error fetching clients:', error);
        });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setAddressData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const token = localStorage.getItem('access_token');

        try {
            const response = await fetch(`${window.location.protocol}//${window.location.hostname}:8443/api/addresses/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(addressData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error('Failed to save address: ' + JSON.stringify(errorData));
            }

            // Reset form
            setAddressData({
                client: '',
                address1: '',
                latitude: '',
                longitude: '',
                year_built: '',
                water: '',
                sewer: '',
                gate_code: '',
                lockbox_code: ''
            });

            const data = await response.json();
            if (onSuccess) onSuccess(data);

        } catch (error) {
            if (onError) onError(error);
        }
    };

    return (
        <div className="container">
            <div className="formGroup">
                <label htmlFor="client" className="label">Customer:</label>
                <select
                    id="client"
                    name="client"
                    value={addressData.client}
                    onChange={handleChange}
                    className="select"
                    required
                >
                    <option value="">Select Customer</option>
                    {clients.map(client => (
                        <option key={client.id} value={client.id}>{client.name}</option>
                    ))}
                </select>
            </div>

            <div className="formGroup">
                <label htmlFor="address1" className="label">Job Site Address:</label>
                <input
                    type="text"
                    id="address1"
                    name="address1"
                    value={addressData.address1}
                    onChange={handleChange}
                    className="input"
                />
            </div>

            <div className="formGroup">
                <label htmlFor="year_built" className="label">Year Built:</label>
                <input
                    type="number"
                    id="year_built"
                    name="year_built"
                    value={addressData.year_built}
                    onChange={handleChange}
                    className="input"
                />
            </div>

            <div className="formGroup">
                <label htmlFor="water" className="label">Water:</label>
                <select
                    id="water"
                    name="water"
                    value={addressData.water}
                    onChange={handleChange}
                    className="select"
                >
                    <option value="">Select Public or Private</option>
                    <option value="public">Public</option>
                    <option value="private">Private</option>
                </select>
            </div>

            <div className="formGroup">
                <label htmlFor="sewer" className="label">Sewer:</label>
                <select
                    id="sewer"
                    name="sewer"
                    value={addressData.sewer}
                    onChange={handleChange}
                    className="select"
                >
                    <option value="">Select Public or Private</option>
                    <option value="public">Public</option>
                    <option value="private">Private</option>
                </select>
            </div>

            <div className="formGroup">
                <label htmlFor="gate_code" className="label">Gate Code:</label>
                <input
                    type="text"
                    id="gate_code"
                    name="gate_code"
                    value={addressData.gate_code}
                    onChange={handleChange}
                    className="input"
                />
            </div>

            <div className="formGroup">
                <label htmlFor="lockbox_code" className="label">Lockbox Code:</label>
                <input
                    type="text"
                    id="lockbox_code"
                    name="lockbox_code"
                    value={addressData.lockbox_code}
                    onChange={handleChange}
                    className="input"
                />
            </div>

            <div className="formGroup">
                <button onClick={handleSubmit} className="button">Save Address</button>
            </div>
        </div>
    );
};

export default AddressForm;
