import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useJobs } from '../jobContext';
import NewJob from '../newJob';
import Modal from 'react-modal';
import './index.css';


const Navbar = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const notify = (text) => toast(text);
  const { refreshTables } = useJobs();

    useEffect(() => {
        setIsAuthenticated(!!localStorage.getItem('access_token'));
    }, []);

  const logout = () => {
    // Remove the tokens from localStorage or any other storage you are using
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    setIsAuthenticated(false);
    // Redirect the user to the login page or home page as required
    window.location.href = '/login';
  };

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
      setIsOpen(true);
  }

  function closeModal() {
      setIsOpen(false);
  }

  return (
    <nav className="navbar">
      <NavLink to="/" exact="true">Home</NavLink>
      <span onClick={openModal} className="navLink">
          New Job
      </span>
      <NavLink to="/customers">Customer Types</NavLink>
      {isAuthenticated && (
        <span onClick={logout} className="navLink">
          Logout
        </span>
      )}

      {isAuthenticated && (
        <NavLink to="/staff">Staff Hours</NavLink>
      )}

      <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Add New Job"
          style={{
              overlay: {
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  zIndex: '4000',
              },
              content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: '5000',
                  overflow: 'auto',
                  maxHeight: '80%',
                  width: '90%',  // Responsive width
              }
          }}
      >
        <h2 style={{fontSize: "1em"}}>New Job <button onClick={closeModal} className="closeButton">Close</button></h2>
        
        <NewJob refreshTables={refreshTables} closeModal={closeModal} />
      </Modal>
    </nav>
  );
};

export default Navbar;
